import { render, staticRenderFns } from "./AlertType.vue?vue&type=template&id=89e53da4&scoped=true&"
import script from "./AlertType.vue?vue&type=script&lang=js&"
export * from "./AlertType.vue?vue&type=script&lang=js&"
import style0 from "./AlertType.vue?vue&type=style&index=0&id=89e53da4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89e53da4",
  null
  
)

export default component.exports